module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'downloadSurveyReport',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/download-report',
           // responseType: 'blob',

            getData: function(payload) {
               // console.log(payload);
                return this.get(payload);
            }
        });
    }]);
};