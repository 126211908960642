module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'getSurveyDetails',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/user/secure/survey',
            cache: false,
            getData: function () {
               // return $q.reject('reason');
                return this.get();
            }
        });
    }]);
};