module.exports = function (app) {
    "use strict";
    app.controller('pages.admin.admin-splash', ['$scope', 'stateService', '$state', function ($scope, stateService, $state) {
        $scope.goToSearchResults = function () {
            $state.go('admin.search-filter');
        };
        $scope.goToFileUpload = function () {
            $state.go('admin.upload-members');
        };
    }]);
}
