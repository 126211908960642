module.exports = function (app) {
    "use strict";
    app.controller('pages.admin.email-confirmation', ['$scope', 'stateService', '$state', function ($scope, stateService, $state){

        $scope.isfile = $state.params.fileUpload;

        $scope.goToSummary = function(){
            $state.go('admin.upload-members');
        };
    }]);
};