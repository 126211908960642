module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.dependents-information', ['$scope', 'stateService', '$state', 'services', function ($scope, stateService, $state, services){

        var dependentObject = {
            firstName: '',
            lastName: '',
            dob: '',
            relationship: ''
        };

        var dependentsStorage = stateService.getData('dependentsStorage');

        var deleteDependentStorage = function(index){
            stateService.deleteKey('dependent_firstName_'+index);
            stateService.deleteKey('dependent_lastName_'+index);
            stateService.deleteKey('dependent_dob_'+index);
            stateService.deleteKey('dependent_relation_'+index);
        };

        $scope.formdata = {};

        if(dependentsStorage){
            $scope.formdata.dependents = dependentsStorage;
        } else {
            $scope.formdata = { dependents: [] };
            //Pushing First Dependent Object
            $scope.formdata.dependents.push(angular.copy(dependentObject));
        }


        $scope.addDependent = function(){
            $scope.formdata.dependents.push(angular.copy(dependentObject));
            stateService.storeData('dependentsStorage', $scope.formdata.dependents);
        };

        $scope.removeDependent = function(index){
            $scope.formdata.dependents.splice(index,1);
            //Remove from local storage too
            deleteDependentStorage(index);
        };

        $scope.submitDependents = function(){
            stateService.storeData('dependentsStorage', $scope.formdata.dependents);
            $state.go('health-survey.dependents-information-review', {dependentReviewed: true});
        };

    }]);
};


