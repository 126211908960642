module.exports = function (app) {
    app.run(["Service", "$q", "session", "$cookies", function (Service, $q, session, $cookies) {
        new Service({
                        name: 'loginDetails',
                        group: 'dps',
                        url: "/dps/x-services/user/authentication",
                        cache: false,

                        login: function(username, password) {
                            var returnDefer = $q.defer();

                            //DO NOT REMOVE - will break IE login
                            this.headers['Cache-Control'] = 'no-cache';
                            this.headers['Pragma'] = 'no-cache';
                            this.headers.Authorization = "Basic " + window.btoa("" + ":" + "");
                            this.headers.loginId = "";
                            if (username && password) {
                                this.headers.Authorization = "Basic " + window.btoa(username + ":" + password);
                                this.headers.loginId = username;
                            } else {
                                this.headers.Authorization = undefined;
                                this.headers.loginId = undefined;
                            }

                            this.flushCache();
                            this.get().then(function(response){
                                $cookies.put("adminUser", true);
                                session.start().then(function() {
                                    returnDefer.resolve(response);
                                });
                            }, function(error) {
                                returnDefer.reject(error);
                            });
                            return returnDefer.promise;
                        }
                    });
    }]);

};