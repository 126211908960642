module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
               name: 'getSurveyDetailsAdmin',
               group: 'cmcrst',
               url: '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/survey',
               cache: false,
               getData: function (id) {
                  this.url += "?surveyId=1&respondentId=" + id;
                  return this.get();
               }
           });
    }]);
};
