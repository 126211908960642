module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'searchSurvey',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/search',
            cache: false,
            postData: function (payload) {
                 return this.post(payload);
            }
        });
    }]);
};