module.exports = function (app) {
    "use strict";

    app.controller('pages.admin.search-results', ['$scope', 'stateService', '$state', 'services', function ($scope, stateService, $state, services){

        $scope.selectedCbox = {};
        $scope.searchResults = null;
        $scope.noResult = false;
        $scope.isServiceFailed = false;
        $scope.isDisabled = true;
        $scope.searchResults = (angular.equals($state.params.searchResults , {}) || angular.equals($state.params.searchResults , null)) ?
            stateService.getData('searchResults') : $state.params.searchResults;

        var searchCriteria = JSON.parse(stateService.getData('searchCriteria'));

        $scope.pagination = {
            itemsPerPage: '10'
        };

        $scope.pagination.page = 1;

        $scope.toggleAll = function() {
            var toggleStatus = $scope.isAllSelected;

            angular.forEach($scope.searchResults, function(itm) {
                $scope.selectedCbox[itm.id] = toggleStatus;
            });
            $scope.isDisabled = !toggleStatus;
        };

        function clearError() {
            $scope.noResult = false;
            $scope.isServiceFailed = false;
        };

        $scope.viewNotes = function(id,status) {

            clearError();

            services.cmcrst.getSurveyDetailsAdmin().getData(id).then(
                function (response) {
                    if(!!response && response.data.baseMessage.returnCode == '200') {
                        stateService.storeData('respondentId', id);
                        stateService.storeData('adminSurveyDetails', response.data.payload);
                        $state.go('admin.questionnaires-notes', {reviewDetails:response.data.payload});
                    }else {
                        $scope.noResult = true;
                    }
                },
                    function(error){
                        $scope.isServiceFailed = true;
                }
            );
        };

        $scope.optionToggled = function(iid,val){
            $scope.selectedCbox[iid] = val;
            if(val == false) {
                $scope.isAllSelected = false;
                if(!JSON.stringify($scope.selectedCbox).match("true")){
                    $scope.isDisabled = true;
                }
            }
            else if(val == true){
                $scope.isDisabled = false;
                var searchLength = $scope.searchResults.length;
                var toggleCount = Object.keys($scope.selectedCbox).map(function(key) {
                    return [Number(key), $scope.selectedCbox[key]];
                }).length;
                if(searchLength === toggleCount && !JSON.stringify($scope.selectedCbox).match("false")){
                    $scope.isAllSelected = true;
                }
            }
        };

        $scope.downloadResults = function() {

            $scope.isDisabled = true;
            clearError();
            var selectedId = [];
            angular.forEach($scope.searchResults, function(itm) {
                if($scope.selectedCbox[itm.id]){
                    selectedId.push(itm.id)
                }
            });

                 var file_path = window.location.origin + '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/download-report?respondentIds=' + selectedId.join(',');

            window.open(file_path, '_self');
            $scope.isDisabled = false;
        };



        $scope.searchAgain = function() {
            $state.go('admin.search-filter');
        };

    }]);
};