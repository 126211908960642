module.exports = function (app) {
    "use strict";

    app.controller('pages.admin.admin-questionnaires-notes', ['$scope', 'stateService', '$state','$stateParams', 'services','surveyUtil', function ($scope, stateService, $state, $stateParams, services,surveyUtil){
        $scope.pages;
        $scope.isPages = true;
        $scope.isServiceFailed = false;
        $scope.isNotesUnavailable = true;
        $scope.availableQuestionnaires = 0;

        var adminSurveyDetails = stateService.getData('adminSurveyDetails') || $state.params.reviewDetails;

        $scope.userResDep =  adminSurveyDetails;
        $scope.getDep =surveyUtil.getSubscriberAndDependents($scope.userResDep);
        $scope.userResDepObj = [];
        angular.forEach($scope.getDep,function (item) {
            var dependent  = !!item.dependentId ? item.dependentId : 0;
            $scope.userResDepObj.push({'id':item.id,'dependentId':dependent,'name': item.firstName + ' ' + item.lastName});
        });
        $scope.availableQuestionnaires = !!$scope.availableQuestionnaires ? $scope.availableQuestionnaires : 0;
        $scope.indiSurvey =  surveyUtil.getSurveyById(adminSurveyDetails,$scope.availableQuestionnaires);
        $scope.pages = $scope.indiSurvey.pages;

        if(adminSurveyDetails.status === "SENT"){
            $scope.isPages = false;
        }

        $scope.goToStep = function(pageId){
            var path = 'health-survey.survey-step'+pageId;
            $state.go(path);
        };

        $scope.getQuestionnaires = function () {
            $scope.availableQuestionnaires = !!$scope.availableQuestionnaires ? $scope.availableQuestionnaires : 0;
            $scope.indiSurvey =  surveyUtil.getSurveyById(adminSurveyDetails,$scope.availableQuestionnaires);
            $scope.pages = $scope.indiSurvey.pages;
        };

        $scope.quesGetValue = function (param) {
            if(!!param) {
                return Object.keys(param).map(function(itm) { return param[itm]; }).join('');
            }
        };


        $scope.quesGetHeight = function (param) {
            if(!!param.Feet) {
                return param.Feet + ' ft ' + param.Inches + ' in';
            } else {
                return '';
            }
        };


        $scope.saveNotes = function(){
            var comment = !!$scope.indiSurvey.comment ? $scope.indiSurvey.comment.Comment : null;
            var req = {'dependentId':$scope.indiSurvey.applicant.dependentId,
                        'respondentId': stateService.getData('respondentId'),
                        'surveyId': "1",
                        'comment':comment,
                        'adminID':null
                    };
            var payload = {
                payload: req
            }
            services.cmcrst.addComment().putData(payload).then(
                function (response) {
                    $scope.isServiceFailed = false;
                    $state.go('admin.changes-confirmation');
                },
                function(error){
                    $scope.isServiceFailed = true;
                });
        };

        $scope.getHeading = function (txt) {
            if(!!txt) {
                return txt.replace(/{APPLICANT NAME}/g, $scope.indiSurvey.applicant.firstName + ' ' + $scope.indiSurvey.applicant.lastName);

            }
        };

        $scope.downloadResults = function(){
            var respondentId = $scope.indiSurvey.applicant.id;
            var file_path = window.location.origin + '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/download-report?respondentIds=' + respondentId;
            window.open(file_path, '_self');
        }

        $scope.notesCheck = function() {
            $scope.isNotesUnavailable = true;
            if(!!$scope.indiSurvey.comment.Comment && $scope.indiSurvey.comment.Comment.length > 0){
                $scope.isNotesUnavailable = false;
            }
        }

    }]);
};