module.exports = function (app) {
    app.directive('medicalConditions', function () {
        return {
            restrict: "A",
            template: require('./medical-conditions.html'),
            scope:{
               conditionText: '@',
                surveyModelData: '=',
                surveyModelKey: '@',
                index: '='
            },
            controller: ['$scope', function($scope){
                $scope.medicalConditionKey = [];
                angular.forEach($scope.surveyModelData, function(value, key) {
                    if(key){
                        $scope.medicalConditionKey.push(parseInt(key));
                    }
                });
                $scope.medicalConditionKey.sort(function(a, b){return a - b});
                $scope.formData = {};
            }]
        };
    });
};
