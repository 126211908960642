module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.survey-step1', ['$scope', 'stateService', '$state', 'services','surveyUtil', function ($scope, stateService, $state, services, surveyUtil){

        $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;
        if(typeof $scope.completeSurvey === 'undefined' || $scope.completeSurvey === null){
            $state.go('health-survey');
        }
        var currentUser =  typeof stateService.getData('currentUser') != "undefined" ? stateService.getData('currentUser') : $scope.survey.currentUser;

        $scope.currentSurvey = $scope.completeSurvey.applicantSubSurveys[currentUser];
        $scope.getUserSureveyStep1 = $scope.currentSurvey.pages["2"];

        $scope.uiMaskValue = ['9', '9?9', '9', '99?9'];

        $scope.completeStep1 = function(){
            $state.go('health-survey.survey-step2');
        };

        $scope.objectToArray = function (value) {
            //return Object.values(value);*/
            return Object.keys(value).map(function(itm) { return value[itm]; });
        };
    }]);
};
