module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.survey-step4', ['$scope', 'stateService', '$state','services','surveyUtil', function ($scope, stateService, $state,services,surveyUtil){

        $scope.submitMedicationDetails = function(){
            $state.go('health-survey.survey-step5');
        };

        $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;
        if(typeof $scope.completeSurvey === 'undefined' || $scope.completeSurvey === null){
            $state.go('health-survey');
        }
        var currentUser =  typeof stateService.getData('currentUser') != "undefined" ? stateService.getData('currentUser') : $scope.survey.currentUser;

        $scope.currentSurvey = $scope.completeSurvey.applicantSubSurveys[currentUser];
        $scope.getUserSureveyStep4 = $scope.currentSurvey.pages["5"];


    }]);
};
