/**
 * @ngDoc filter
 * @name phoneNumber
 * @description Formats a 10-digit number as a phone number: (###) ###-####
 */

module.exports = function (app) {
    app.filter('phoneNumber', function () {
        return function (value) {
            value = value || '';

            if (value.length < 1) {
                return '';
            }

            return '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-' + value.slice(6, 10);
        };
    });
};