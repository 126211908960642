/**
 * Created by lidc8nw on 10/31/2018.
 */

module.exports = function (app) {
    "use strict";
    app.controller('pages.admin.upload-members', ['$scope', 'stateService', '$state','services', '$window', function ($scope, stateService, $state,services, $window) {
        $scope.isError = false;
        $scope.isServiceFailed = false;
        $scope.isManualServiceFailed = false;
        $scope.process = false;
        $scope.isManualSubmit = true;
        $scope.formData = {};
        $scope.formData.id = null;

        $scope.downloadEtemplate = function () {
            $window.location.href="/dpsint/cmcrst/x-services/healthsurvey/user/public/email-template";
        };

        var handleUploadEmailError = function(error){
            if (error && error.data && error.data.baseMessage && error.data.baseMessage.errorMessage) {
                $scope.errorMessage = error.data.baseMessage.errorMessage;
            } else {
                $scope.errorMessage = 'Oops something seems to have gone wrong. Please try again.';
            }
        };

        $scope.uploadEtemplate = function () {
            $scope.process = true;
            $scope.isServiceFailed = false;

            var file = $scope.uploadDoc;
            var allowedExt = ['xls','csv','xlsx'];
            var ext = file.name.split('.')[1];

            if(allowedExt.indexOf(ext) > -1){
                services.cmcrst.uploadEmail().postData(file).then(
                    function(res){
                        $scope.process = false;
                        $state.go('admin.email-confirmation',{fileUpload:true});
                    },
                    function (error) {
                        handleUploadEmailError(error);
                        $scope.isServiceFailed = true;
                        $scope.process = false;
                    }
                );
            }
        };

        $scope.validData = function() {
            if((!!$scope.formData.firstName && $scope.formData.firstName.length > 0) &&
                (!!$scope.formData.lastName && $scope.formData.lastName.length > 0) &&
                (!!$scope.formData.dob && $scope.formData.dob.length > 0) &&
                (!!$scope.formData.email && $scope.formData.email.length > 0) &&
                (!!$scope.formData.group && $scope.formData.group.length > 0) &&
                (!!$scope.formData.employer && $scope.formData.employer.length > 0)){
                $scope.isManualSubmit = false;
            }
        };

        $scope.manualEntrySubmit = function () {
            $scope.isManualSubmit = true;
            $scope.isManualServiceFailed = false;
            var payload = {
                payload: {
                    respondent: $scope.formData,
                    surveyId: "1"
                }
            };

            services.cmcrst.sendEmail().postData(payload).then(

                function(res){
                    $state.go('admin.email-confirmation',{fileUpload:false});
                },
                function (error) {
                    $scope.isManualServiceFailed = true;
                    $scope.isManualSubmit = false;
                }
            );
        };
    }]);


}
