module.exports = function (app) {

    app.directive('dateField', function ($compile) {
        return {
            "restrict": "A",
            "terminal": true,
            "priority": 1000,
            link: function (scope, elem) {
                elem.attr("placeholder", "MM/DD/YYYY");
                elem.attr("maxlength", "10");
                elem.attr('data-ng-model-options', "{'updateOn': 'blur'}");
                elem.attr("data-date-input-field", "");
                elem.removeAttr("date-field");
                elem.removeAttr("data-date-field");

                if (!elem.attr('data-date-max')) {
                    elem.attr('data-date-max', new Date('12/31/2099'));
                }
                if (!elem.attr('data-date-min')) {
                    elem.attr('data-date-min', new Date('1/1/1900'));
                }

                $compile(elem)(scope);
            }
        };
    });

    app.directive('dateInputField', function ($filter, $timeout) {
        return {
            "restrict": "A",
            "require": 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$parsers.push(function (inputValue) {
                    var transformedInput = inputValue.toLowerCase().replace(/[^0-9\/]/g, '');
                    if (transformedInput != inputValue) {
                        ctrl.$setViewValue(transformedInput);
                        ctrl.$render();
                    }
                    return transformedInput;
                });

                var listener = function () {
                    var value = ctrl.$viewValue || '';

                    var convertedValue = "";
                    var splitValue = value.split("/");
                    var tempDate = new Date();
                    var tmpYear = tempDate.getFullYear() + "";
                    var currentYear = Number(tmpYear.substring(Math.max(tmpYear.length - 2, 0)));
                    if (!isNaN(currentYear)) {
                        currentYear += 1;
                    } else {
                        currentYear = 0;
                    }

                    if (splitValue.length === 3) {
                        if ((splitValue[2] && splitValue[2].length === 2) && splitValue[0].length !== 4) {
                            if (!isNaN(splitValue[2]) && splitValue[2] > currentYear) {
                                splitValue[2] = "19" + splitValue[2];
                            } else {
                                splitValue[2] = "20" + splitValue[2];
                            }
                        }

                        // ensure max 4 digit year
                        splitValue[2] = splitValue[2].substring(0, 4);

                        if (splitValue[0] && splitValue[0].length === 1) {
                            splitValue[0] = "0" + splitValue[0];
                        }
                        if (splitValue[1] && splitValue[1].length === 1) {
                            splitValue[1] = "0" + splitValue[1];
                        }

                        if (splitValue[1].length === 0 && splitValue[2].length === 0) {
                            convertedValue = splitValue[0];
                        } else {
                            convertedValue = splitValue[0].length !== 4 ? splitValue.join("/") : splitValue[1] + "/" + splitValue[2] + "/" + splitValue[0];
                        }
                    } else if (splitValue.length === 2 && splitValue[1].length > 1) {
                        convertedValue = splitValue[0].splice(2, 0, "/") + splitValue[1].splice(2, 0, "/");
                    } else if (splitValue.length === 1 && splitValue[0].length > 1) {
                        convertedValue = splitValue[0].splice(2, 0, "/");
                    } else if (splitValue.length > 3) {
                        splitValue.pop();
                        convertedValue = splitValue.join("/");
                    }
                    else {
                        convertedValue = value;
                    }
                    elem.val(convertedValue);
                    ctrl.$setViewValue(convertedValue);
                    // if (convertedValue) {
                    //     ctrl.$setValidity("date", isValidDate(convertedValue));
                    // }
                };

                elem.bind('blur', listener);

                if (attrs.hasOwnProperty("enableAutoPop")) {
                    elem.bind('change', listener);

                    elem.bind('keydown', function (event) {
                        var key = event.keyCode;

                        if (key == 91 || (15 < key && key < 19) || (37 <= key && key <= 40) || key == 8 || key == 46) {
                            return;
                        }

                        $timeout(listener, 1000);
                    });
                }

                elem.bind('paste cut', function () {
                    $timeout(listener);
                });

                var isValidDate = ctrl.$validators.date = function (value) {
                    value = value || '';

                    if (value.length === 0) {
                        return true;
                    }

                    var tempDate = new Date(value);
                    var splitValue = value.split("/");
                    return (
                        (tempDate.getMonth() + 1) === parseInt(splitValue[0]))
                        && (tempDate.getDate() === parseInt(splitValue[1])
                        && (tempDate.getFullYear() === parseInt(splitValue[2]))
                        );
                };
                if (!String.prototype.splice) {
                    /**
                     * {JSDoc}
                     *
                     * The splice() method changes the content of a string by removing a range of
                     * characters and/or adding new characters.
                     *
                     * @this {String}
                     * @param {number} start Index at which to start changing the string.
                     * @param {number} delCount An integer indicating the number of old chars to remove.
                     * @param {string} newSubStr The String that is spliced in.
                     * @return {string} A new string with the spliced substring.
                     */
                    String.prototype.splice = function (start, delCount, newSubStr) {
                        return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
                    };
                }
            }
        };
    });
};
