module.exports = function (app) {
    "use strict";

    app.controller('pages.health-survey.survey-review', ['$scope', 'stateService', '$state', 'services','surveyUtil', 'coremetrics', function ($scope, stateService, $state, services, surveyUtil, coremetrics){


        $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;
        if(typeof $scope.completeSurvey === 'undefined' || $scope.completeSurvey === null){
            $state.go('health-survey');
        }
        $scope.reviewAndSubmit = stateService.getData('reviewAndSubmit') || $state.params.reviewAndSubmit;
        var userID =  typeof stateService.getData('currentUser') != "undefined" ? stateService.getData('currentUser') : $scope.survey.currentUser;
        var dependents = surveyUtil.getDependentsOnly($scope.completeSurvey);
        $scope.withDependents = dependents.length > 0;

        if($scope.reviewAndSubmit){
            $scope.selectedSurvey = userID;
            var allUsersPayload =surveyUtil.getSubscriberAndDependents($scope.completeSurvey);
            $scope.allUsers = [];
            angular.forEach(allUsersPayload,function (item) {
                var dependent  = !!item.dependentId ? item.dependentId : 0;
                $scope.allUsers.push({'id':item.id,'dependentId':dependent,'name': item.firstName + ' ' + item.lastName});
            });
        }

        $scope.getSurveyBySelection = function () {
            $scope.survey.currentUser = $scope.selectedSurvey;
            stateService.storeData('currentUser',$scope.selectedSurvey.toString());
            userID = $scope.selectedSurvey;
            $scope.currentSurvey =  $scope.completeSurvey.applicantSubSurveys[$scope.selectedSurvey];
            $scope.pages = $scope.currentSurvey.pages;
        };


        $scope.currentSurvey = $scope.completeSurvey.applicantSubSurveys[userID];
        $scope.pages = $scope.currentSurvey.pages;

        $scope.quesGetValue = function (param) {
            if(param !== null) {
                return Object.keys(param).map(function(itm) { return param[itm]; }).join('');
            }
        };

        $scope.quesGetHeight = function (param) {
            if(param.Feet != null) {
                return param.Feet + ' ft ' + param.Inches + ' in';
            } else {
                return '';
            }
        };

        $scope.editPage = function(index){
            if(userID.toString() === "0"  && index === 0){
                $state.go('health-survey.phone-number-designation');
            }else {
                if(userID.toString() !== "0"){
                    index = index+1;
                    $state.go('health-survey.survey-step' + index);
                }
                $state.go('health-survey.survey-step' + index);
            }
        };

        $scope.submitPage = function(){
            $scope.loadingService = true;
            if($scope.completeSurvey){
                services.cmcrst.saveSurveyDetails().putData({payload: $scope.completeSurvey}).then(
                    function(response){ // With dependents
                        $scope.loadingService = false;

                        //If the dependents exist and if all the surveys are not completed
                        if(dependents.length > 0 && !surveyUtil.allSurveysCompleted($scope.completeSurvey)){
                            $scope.completeSurvey.applicantSubSurveys[userID].subComplete = true;
                            stateService.storeData('completeSurvey', $scope.survey.formData);
                            $state.go('health-survey.dependents-selection');
                        } else if(surveyUtil.allSurveysCompleted($scope.completeSurvey) || $scope.reviewAndSubmit || dependents.length === 0){ //If all the surveys are completed or Subscriber Only or if it is a review and submit flow
                            services.cmcrst.submitUserSurveyDetails().putData({payload: $scope.completeSurvey}).then(
                                function(response){
                                    if(dependents.length > 0){
                                        coremetrics.finishConversion('Complete Survey', 'Dependent Complete Survey');
                                    } else {
                                        coremetrics.finishConversion('Complete Survey', 'Self Complete Survey');
                                    }
                                    stateService.deleteKey('reviewAndSubmit');
                                    coremetrics.elementTag('Submit', 'HEALTH SURVEY');
                                    $state.go('health-survey.submission-confirmation');
                                },
                                function(surveyError){
                                    console.log(surveyError);
                                    surveyUtil.ifNoAccess(surveyError);
                                }
                            );
                        }
                    },
                    function(surveyError){
                        $scope.loadingService = false;
                        $scope.SubmitServiceError = true;
                        console.log(surveyError);
                    }
                );
            }
        };

        $scope.getPageTitle = function(index){
            if(userID.toString() === '0'  && index.toString() === '0'){
                return $scope.pages["1"].text;
            }
            else{
                if(userID.toString() !== '0'){
                    index = index+1;
                }
                return "STEP "+index;
            }
        };
    }]);
};



