module.exports = function(app) {
    app.directive('svyAppHeader', ['brand', 'services', '$state', 'stateService', 'sessionData', 'session', function(brand, services, $state, stateService, sessionData, session) {
        return {
            restrict: 'A',
            replace: true,
            template: require('directives/svy-app-header.html'),

            link: function(scope, element) {
                scope.sessionData = sessionData;
                scope.logoutAdmin = function(){
                    services.dps.logout().logout().then(
                        function (response) {
                            stateService.flush();
                            session.end();
                        },
                        function (error) {
                            console.log(error);
                        }
                    );
                };
                //scope.isAdminLogin = ($state.current.name === 'admin');
            }
        };
    }]);
};


