module.exports = function (app) {
    app.service('surveyUtil', ['sessionData', 'stateService', 'services', '$state', 'coremetrics', function (sessionData, stateService, services, $state, coremetrics) {

        var _isSurveyCompleted = function(surveyObject){
            return surveyObject.complete === true;
        };

        this.getSubscriberAndDependents = function(survey){
            var members = [];
            if(survey){
                var surveys = survey.applicantSubSurveys;
                for(var dependent in surveys){
                    members.push(surveys[dependent].applicant);
                }

                return members;
            }
        };

        this.ifNoAccess = function(error){
            if (error && error.status && error.status.toString() === '403') {
                stateService.flush();
                coremetrics.elementTag('Session Time Out or Forbidden', 'HEALTH SURVEY');
                $state.go('health-survey');
            }
        };

        this.getDependentsOnly = function(survey){
            var dependents = [];
            if(survey){
                var surveys = survey.applicantSubSurveys;

                for(var dependent in surveys){
                    if(dependent.toString() !== '0'){
                        dependents.push(surveys[dependent].applicant);
                    }
                }

                return dependents;
            }
        };

        this.getPageById = function(survey, surveyId, pageId){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                return surveys[surveyId].pages[pageId];
            }

        };

        this.getUserSurvey = function(survey, id){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                return surveys[id];
            }
        };

        this.isApplicantSurveyComplete = function(survey, id){
            if(survey){
                var isApplicantSurveyComplete = survey.applicantSubSurveys[id].subComplete;
                return isApplicantSurveyComplete;
            }
        };

        this.allSurveysCompleted = function(survey){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                for(var dependent in surveys){
                    if(!surveys[dependent].subComplete){
                        return false;
                    }
                }
            }

            return true;
        };

        this.allSurveysIncomplete = function(survey){
            var surveys = survey.applicantSubSurveys;
            for(var dependent in surveys){
                if(surveys[dependent].subComplete){
                    return false;
                }
            }
            return true;
        };

        this.getSurveyById = function(survey, id){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                return surveys[id];
            }
        };

        this.getNextSurvey = function(survey){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                for(var dependent in surveys){
                    if(!surveys[dependent].subComplete){
                        return surveys[dependent];
                    }
                }
            }
        };

        this.getNextUserKey = function(survey){
            if(survey){
                var surveys = survey.applicantSubSurveys;
                for(var dependent in surveys){
                    if(!surveys[dependent].subComplete){
                        return dependent;
                    }
                }
            }
        };
    }]);
};
