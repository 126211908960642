module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.dependents-identification', ['$scope', 'stateService', '$state', 'services', 'surveyUtil', '$rootScope', 'coremetrics', function ($scope, stateService, $state,  services, surveyUtil, $rootScope, coremetrics){

       /* $scope.isSelfEnrolled = false;*/
        coremetrics.finishConversion('Login', 'Health Survey Login');

        $scope.goToDependentsInformation = function(){
            $rootScope.flushStateService();
            coremetrics.elementTag('Complete Survey for Dependents', 'HEALTH SURVEY');
            coremetrics.startConversion('Complete Survey', 'Dependent Complete Survey');
            $state.go('health-survey.dependents-information');
        };

        $scope.goToPhoneNumberDesignation = function(){
            $rootScope.flushStateService();
            if($rootScope.surveyStatus && $rootScope.surveyStatus.toUpperCase() === "SENT"){
                services.cmcrst.getSurveyDetails().getData().then(
                    function(surveyResponse){
                        if(surveyResponse.data.payload){
                            stateService.storeData('completeSurvey',surveyResponse.data.payload);
                            $scope.survey.formData = surveyResponse.data.payload;
                            $scope.survey.currentUser = 0;
                            stateService.storeData('currentUser', 0);
                            coremetrics.elementTag('Complete Survey for Self', 'HEALTH SURVEY');
                            coremetrics.startConversion('Complete Survey', 'Self Complete Survey');
                            $state.go('health-survey.phone-number-designation');
                        }
                    },
                    function(surveyError){
                        console.log(surveyError);
                        surveyUtil.ifNoAccess(surveyError);
                    }
                );
            }
        };

        $scope.goToSurvey = function(){
            $rootScope.flushStateService();
            $state.go('health-survey');
        };

        /*$scope.goToPendingDependentsInformation = function(){
            $state.go('health-survey.phone-number-designation');
        };*/
    }]);
};