module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.dependents-information-review', ['$scope', 'stateService', '$state', 'services', 'surveyUtil', function ($scope, stateService, $state, services, surveyUtil){

        if(!$state.params.dependentReviewed){
            $state.go('health-survey.dependents-information');
        }
        var dependentsStorage = stateService.getData('dependentsStorage');

        $scope.dependentsInformation = [];

        if(dependentsStorage){
            $scope.dependentsInformation = dependentsStorage;
        }

        $scope.goToDependentsInformation = function(){
            $state.go('health-survey.dependents-information');
        };

        $scope.submitDependentsInformation = function(){
            if($scope.dependentsInformation){
                var payload = {
                    payload: $scope.dependentsInformation
                };
                services.cmcrst.addDependents().postData(payload).then(
                    function(response){
                        services.cmcrst.getSurveyDetails().getData().then(
                            function(surveyResponse){
                                if(surveyResponse.data.payload){
                                    stateService.storeData('completeSurvey',surveyResponse.data.payload);
                                    $scope.survey.formData = surveyResponse.data.payload;
                                    var dependentsStorage = stateService.getData('dependentsStorage');
                                    for (var i = 0; i < dependentsStorage.length; ++i) {
                                        stateService.deleteKey('dependent_firstName_'+i);
                                        stateService.deleteKey('dependent_lastName_'+i);
                                        stateService.deleteKey('dependent_dob_'+i);
                                        stateService.deleteKey('dependent_relation_'+i);
                                    }
                                    stateService.deleteKey('dependentsStorage');
                                    $state.go('health-survey.dependents-selection');
                                }
                            },
                            function(surveyError){
                                console.log(surveyError);
                                surveyUtil.ifNoAccess(surveyError);
                            }
                        );
                    },
                    function(error){
                        surveyUtil.ifNoAccess(error);
                    }
                );
            }
        };

    }]);
};



