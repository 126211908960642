module.exports = function (app) {
    app.run(["Service", "$q", "session", function (Service, $q, session) {
        new Service({
             name: 'logout',
             group: 'dps',
             url: "/dps/x-services/user/logout",

             logout: function() {
                var returnDefer = $q.defer();

                this.del().then(function(response){
                         returnDefer.resolve(response);
                   }, function(error) {
                         returnDefer.reject(error);
                   });
                         return returnDefer.promise;
                   }
                });
    }]);

};
