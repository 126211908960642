module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.survey-cancel', ['$scope', 'stateService', '$state', function ($scope, stateService, $state){

        $scope.goToCancelSuccess = function(){
            $state.go('health-survey.cancel-success');
        };
        $scope.goToSaveAndFinish = function(){
            $state.go('health-survey.save-finishlater');
        };

    }]);
};