module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'addComment',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/comment',
            putData: function (payload) {
                return this.put(payload);
            }
        });
    }]);
};