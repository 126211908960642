module.exports = function(app) {
    app.directive('svyAppFooter', ['brand', function(brand) {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: '_directives/svy-app-footer.svywebTemplate.html/directives/svy-app-footer',
            link: function(scope, element) {

            }
        };
    }]);
};
