module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.survey-step5', ['$scope', 'stateService', '$state', 'services', 'surveyUtil', function ($scope, stateService, $state, services, surveyUtil){

        $scope.submitPregnancyDetails = function(){
            $state.go('health-survey.survey-review');
        };

        $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;
        if(typeof $scope.completeSurvey === 'undefined' || $scope.completeSurvey === null){
            $state.go('health-survey');
        }
        var currentUser =  typeof stateService.getData('currentUser') != "undefined" ? stateService.getData('currentUser') : $scope.survey.currentUser;

        $scope.currentSurvey = $scope.completeSurvey.applicantSubSurveys[currentUser];
        $scope.getUserSureveyStep5 = $scope.currentSurvey.pages["6"];

    }]);
};
