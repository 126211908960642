module.exports = function (app) {
    "use strict";

    require('./survey/dependents-identification')(app);
    require('./survey/dependents-selection')(app);
    require('./survey/dependents-information')(app);
    require('./survey/dependents-information-review')(app);
    require('./survey/phone-number-designation')(app);
    require('./survey/survey-submission-confirmation')(app);
    require('./survey/survey-support')(app);
    require('./survey/survey-cancel')(app);
    require('./survey/survey-step1')(app);
    require('./survey/survey-step2')(app);
    require('./survey/survey-step3')(app);
    require('./survey/survey-step4')(app);
    require('./survey/survey-step5')(app);
    require('./survey/cancel-success')(app);
    require('./survey/saveandfinishlater')(app);
    require('./survey/survey-review')(app);

    app.controller('pages.health-survey', ['$scope', 'stateService', '$state', 'services', '$rootScope','$location', '$window', 'surveyUtil', '$cookies', 'coremetrics',
        function ($scope, stateService, $state, services, $rootScope, $location, $window, surveyUtil, $cookies, coremetrics){

        var $outputJson = $location.search().emailId ? $location.search().emailId : null;

        $cookies.remove("adminUser");

        $scope.headings = "MEDICAL QUESTIONNAIRE";
        $scope.formData = {
            firstName: null,
            lastName: null,
            emailAddress: null,
            dob: null,
            emailId: $outputJson
        };

        $rootScope.surveyCurrentScope = [];
        $rootScope.goBack = function(){
            $window.history.back();
        };

        $rootScope.isMember = true;
        $rootScope.isAdmin = false;

        $rootScope.supportPage = function(){
            coremetrics.elementTag('Click for Help', 'HEALTH SURVEY');
            $state.go('health-survey.survey-support');
        };

        $rootScope.saveApplication = function(){
            coremetrics.elementTag('Save Survey', 'HEALTH SURVEY');
            $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;

            if($scope.completeSurvey){
                services.cmcrst.saveSurveyDetails().putData({payload: $scope.completeSurvey}).then(
                    function(response){
                        $state.go('health-survey.save-finishlater');
                    },
                    function(surveyError){
                        console.log(surveyError);
                        surveyUtil.ifNoAccess(surveyError);
                    }
                );
            }
        };

        $rootScope.getHeading = function (txt, currentSurvey) {
            if(txt != null) {
                return txt.replace(/{APPLICANT NAME}/g, currentSurvey.applicant.firstName + ' ' + currentSurvey.applicant.lastName);
            }
        };

        $rootScope.cancelSurvey = function(){
            coremetrics.elementTag('Cancel', 'HEALTH SURVEY');
            $state.go('health-survey.survey-cancel');
        };

        $rootScope.returnHome = function(){
            coremetrics.elementTag('Return to Home', 'HEALTH SURVEY');
            $state.go('health-survey');
        };


        $scope.serviceError = false;
        $scope.serviceClientError = false;
        $rootScope.serviceErrorList = [500,502,503,504,511];
        $rootScope.serviceClientErrorList = [400,401,403,404,407,408];

        $scope.authenticateMember = function(){
            if($location.search().emailId) {
                var payload = {payload: $scope.formData};
                $scope.serviceError = false;
                $scope.serviceClientError = false;
                services.cmcrst.authenticateUser().postData(payload).then(
                    function (response) {
                        if (response.data.payload.status) {
                            $rootScope.surveyStatus = response.data.payload.status;

                            if ($rootScope.surveyStatus && $rootScope.surveyStatus.toUpperCase() === "IN_PROGRESS") {
                                services.cmcrst.getSurveyDetails().getData().then(
                                    function (surveyResponse) {
                                        if (surveyResponse.data.payload) {
                                            stateService.storeData('completeSurvey', surveyResponse.data.payload);
                                            $scope.survey.formData = surveyResponse.data.payload;
                                            $scope.survey.currentUser = surveyUtil.getNextUserKey($scope.survey.formData);
                                            !$scope.survey.currentUser ? ($scope.survey.currentUser = 0) : $scope.survey.currentUser;
                                            stateService.storeData('currentUser', $scope.survey.currentUser);
                                            $state.go('health-survey.dependents-selection');
                                        }
                                    },
                                    function (surveyError) {
                                        console.log(surveyError);
                                        surveyUtil.ifNoAccess(surveyError);
                                    }
                                );
                            } else if (($rootScope.surveyStatus.toUpperCase() === "EXPIRED") || ($rootScope.surveyStatus.toUpperCase() === "COMPLETE")) {
                                if ($rootScope.surveyStatus.toUpperCase() === "EXPIRED") {
                                    $state.go('health-survey.expired');
                                }
                                if ($rootScope.surveyStatus.toUpperCase() === "COMPLETE") {
                                    $state.go('health-survey.completed');
                                }
                            } else {
                                coremetrics.startConversion('Login', 'Health Survey Login');
                                $state.go('health-survey.dependents-identification');
                            }
                        }
                    },
                    function (error) {
                        $scope.servicesError = false;
                        $scope.serviceClientError = false;
                        if ($rootScope.serviceErrorList.indexOf(error.status) !== -1) {
                            $scope.serviceError = true;
                        }
                        if ($rootScope.serviceClientErrorList.indexOf(error.status) !== -1) {
                            $scope.serviceClientError = true;
                        }
                    }
                );
            } else{
                $scope.isAuthorized = $location.search().emailId ? false : true;
            }
        };
    }]);

    return [
        {
            state: "landing-page",
            url: "/",
            views: {}
        },
        {
            state: "health-survey",
            url: "/health-survey",
            views: {
                'header@': {
                    template: '<article data-svy-app-header></article>'
                },
                'body@': {
                    template: require('page-templates/content-template.html'),
                    controller: "pages.health-survey"
                },
                'content@health-survey': {
                    templateUrl: "health-survey.svywebTemplate.html/pages/health-survey/health-survey"
                },
                'footer':{
                    template: '<article data-svy-app-footer></article>'
                }
            }
        },
        {
            state: "health-survey.dependents-selection",
            url: "/dependents-selection",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/dependents-selection.svywebTemplate.html/pages/health-survey/survey/dependents-selection",
                    controller: "pages.health-survey.dependents-selection"
                }
            }
        },
        {
            state: "health-survey.dependents-information",
            url: "/dependents-information",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/dependents-information.svywebTemplate.html/pages/health-survey/survey/dependents-information",
                    controller: "pages.health-survey.dependents-information"
                }
            }
        },
        {
            state: "health-survey.dependents-information-review",
            url: "/dependents-review",
            params: {dependentReviewed: null},
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/dependents-review.svywebTemplate.html/pages/health-survey/survey/dependents-information-review",
                    controller: "pages.health-survey.dependents-information-review"
                }
            }
        },
        {
            state: "health-survey.phone-number-designation",
            url: "/phone-number",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/phone-number.svywebTemplate.html/pages/health-survey/survey/phone-number-designation",
                    controller: "pages.health-survey.phone-number-designation"
                }
            }
        },
        {
            state: "health-survey.dependents-identification",
            url: "/dependents-identification",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/dependents-identification.svywebTemplate.html/pages/health-survey/survey/dependents-identification",
                    controller: "pages.health-survey.dependents-identification"
                }
            }
        },
        {
            state: "health-survey.select-dependent",
            url: "/select-dependent",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/select-dependent.svywebTemplate.html/pages/health-survey/survey/select-dependent"
                }
            }
        },
        {
            state: "health-survey.faq",
            url: "/faq",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/faq.svywebTemplate.html/pages/health-survey/survey/faq"
                }
            }
        },
        {
            state: "health-survey.survey-step1",
            url: "/survey-step1",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-step1.svywebTemplate.html/pages/health-survey/survey/survey-step1",
                    controller: "pages.health-survey.survey-step1"
                }
            }
        },
        {
            state: "health-survey.survey-step2",
            url: "/survey-step2",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-step2.svywebTemplate.html/pages/health-survey/survey/survey-step2",
                    controller: "pages.health-survey.survey-step2"
                }
            }
        },
        {
            state: "health-survey.survey-step3",
            url: "/survey-step3",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-step3.svywebTemplate.html/pages/health-survey/survey/survey-step3",
                    controller: "pages.health-survey.survey-step3"

                }
            }
        },
        {
            state: "health-survey.survey-step4",
            url: "/survey-step4",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-step4.svywebTemplate.html/pages/health-survey/survey/survey-step4",
                    controller: "pages.health-survey.survey-step4"
                }
            }
        },
        {
            state: "health-survey.survey-step5",
            url: "/survey-step5",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-step5.svywebTemplate.html/pages/health-survey/survey/survey-step5",
                    controller: "pages.health-survey.survey-step5"
                }
            }
        },
        {
            state: "health-survey.submission-confirmation",
            url: "/submission-confirmation",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/submission-confirmation.svywebTemplate.html/pages/health-survey/survey/survey-submission-confirmation",
                    controller: "pages.health-survey.survey-submission-confirmation"
                }
            },
            onExit: function ($rootScope) {
                $rootScope.flushStateService();
            }
        },
        {
            state: "health-survey.survey-support",
            url: "/survey-support",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-support.svywebTemplate.html/pages/health-survey/survey/survey-support",
                    controller: "pages.health-survey.survey-support"
                }
            }
        },
        {
            state: "health-survey.survey-cancel",
            url: "/survey-cancel",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-cancel.svywebTemplate.html/pages/health-survey/survey/survey-cancel",
                    controller: "pages.health-survey.survey-cancel"
                }
            }
        },
        {
            state: "health-survey.cancel-success",
            url: "/cancel-success",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/cancel-success.svywebTemplate.html/pages/health-survey/survey/cancel-success",
                    controller: "pages.health-survey.cancel-success"
                }
            },
            onExit: function ($rootScope) {
                $rootScope.flushStateService();
            }
        },
        {
            state: "health-survey.save-finishlater",
            url: "/save-finishlater",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/save-finishlater.svywebTemplate.html/pages/health-survey/survey/saveandfinishlater",
                    controller: "pages.health-survey.saveandfinishlater"
                }
            },
            onExit: function ($rootScope) {
                $rootScope.flushStateService();
            }
        },
        {
            state: "health-survey.survey-review",
            url: "/survey-review",
            params: {
                reviewAndSubmit: false
            },
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/survey-review.svywebTemplate.html/pages/health-survey/survey/survey-review",
                    controller: "pages.health-survey.survey-review"
                }
            }
        },
        {
            state: "health-survey.expired",
            url: "/expired",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/expired.svywebTemplate.html/pages/health-survey/survey/expired"
                }
            },
            onExit: function ($rootScope) {
                $rootScope.flushStateService();
            }
        },
        {
            state: "health-survey.completed",
            url: "/completed",
            views: {
                'content@health-survey': {
                    templateUrl: "health-survey/completed.svywebTemplate.html/pages/health-survey/survey/completed"
                }
            },
            onExit: function ($rootScope) {
                $rootScope.flushStateService();
            }
        }
    ];
};

