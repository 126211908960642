var app = angular.module('app', ['bootstrap.main', 'ui.mask', 'angular-loading-bar']);
app.value('appId', 'SVYWEB');
window.NOGOOGLEMAPSLOL = true;  // Temp solution to kill google maps per project

// UI Services


//services
require('./services/cmcrst/addDependent')(app);
require('./services/cmcrst/addDependents')(app);
require('./services/cmcrst/removeDependent')(app);
require('./services/cmcrst/authenticateUser')(app);
require('./services/cmcrst/getEmailTemplate')(app);
require('./services/cmcrst/uploadEmail')(app);
require('./services/cmcrst/downloadSurveyReport')(app);
require('./services/cmcrst/getSurveyDetails')(app);
require('./services/cmcrst/getSurveyDetailsAdmin')(app);
require('./services/cmcrst/saveSurveyDetails')(app);
require('./services/cmcrst/submitSurveyDetails')(app);
require('./services/cmcrst/searchSurvey')(app);
require('./services/cmcrst/sendEmail')(app);
require('./services/cmcrst/entitlementService')(app);
require('./services/cmcrst/addComment')(app);
require('./services/dps/login')(app);
require('./services/dps/logout')(app);
require('./services/surveyUtility')(app);
require('./services/stateService')(app);

//Directives
require('./directives/svy-app-footer')(app);
require('./directives/svy-app-header')(app);
require('./directives/validators/dateMinMax')(app);
require('./directives/validators/validateName')(app);
require('./directives/validators/employerName')(app);
require('./directives/dateField')(app);
require('./directives/medical-conditions')(app);
require('./directives/form-modelling')(app);


// Filters
require('./filters/phoneNumber')(app);
require('./filters/capitalize')(app);

//Constants


// Routes
// jshint ignore: start
var routes = [];
routes = routes.concat(([]).reduce(function(acc, data) {
    var route = {
        state: data.path.join('.'),
        url: '/' + data.page.name,
        postPassword: true,
        views: {
            "resources": {
                controller: ["$scope", function ($scope) {
                    window.bbb = $scope;
                    $scope.sidebar.betterBreadcrumbs.splice(data.path.length - 2, 999, {
                        title: ((data.path.length - 1 == 1) ? "" : "") + data.page.props["navTitle"] || "Missing Heading",
                        state: data.path.join('.')
                    });
                }]
            }
        }
    };
    if (data.page.children) {
        var template = '<p style="margin-bottom: 24px;">' + (data.page.props["jcr:description"] || "Missing Description") + '</p>';
        data.page.children.forEach(function(child) {
            if (child.props["externalURL"]) {
                if (child.props["newWindow"]) {
                    template += "<a href=\"" + child.props["externalURL"] + "\" target=\"_blank\">" + child.props["navTitle"] + "</a>";
                }
                else {
                    template += "<a href=\"" + child.props["externalURL"] + "\">" + child.props["navTitle"] + "</a>";
                }
            }
            else {
                template += "<a data-ui-sref=\"" + child.path.join('.') + "\">" + child.props["navTitle"] + "</a>";
            }
            template += "<p style=\"margin-bottom: 12px;\">" + child.props["jcr:description"] + "</p>";
        });
        route.views["resources"].template = '<section data-ui-view="resources" style="min-height: 400px;">' + template + '</section>';
    }
    else {
        route.views["resources"].templateUrl = data.path.join('/') + '.svywebTemplate.html/pages/resources/aem';
    }
    if (data.path.join('.') !== "resources") {
        acc.push(route);  // Temp hack for resources route error
    }
    return acc;
}, [])); // jshint ignore: end
routes = routes.concat(require('./pages/admin/admin')(app));
routes = routes.concat(require('./pages/health-survey/health-survey')(app));

for (var i = 0; i < routes.length; i++) {
    var s = routes[i].url.split("?");
    s[0] = s[0] + "{url:(?:.html)?}";
    routes[i].url = s.join("?");

}

app.service('routes', function () { return routes; });

app.run(['brand', 'brandConfig', '$rootScope', 'stateService', '$location', 'hook', 'navigation', 'session', 'services', '$cookies', '$state', '$q', function(brand, brandConfig, $rootScope, stateService, $location, hook, navigation, session, services, $cookies, $state, $q) {
    brand.override = 'WYBCBS';
    brandConfig.update();

    $rootScope.survey = {
        formData: {},
        currentUser: null
    };

    $rootScope.$watch('survey.formData', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            stateService.storeData('completeSurvey', newValue);
        }
    }, true);

    $rootScope.$on('$locationChangeSuccess', function() {
        $rootScope.actualLocation = $location.path();
    });

    $rootScope.$watch(function () {return $location.path()}, function (newLocation, oldLocation) {
        if($rootScope.actualLocation === newLocation) {
            $rootScope.gobackLocation = newLocation;
        }
    });

    //session expires
    session.logoutWithRedirect = function(){
        services.dps.logout().logout().then(
            function (response) {
                stateService.flush();
                session.end();
            },
            function (error) {
                console.log(error);
            }
        );
    }

    //If you try to hit pre-password page
    session.redirect = function(){
       $state.go('admin');
    }

    $rootScope.flushStateService = function () {
        stateService.getAllKeys();
        stateService.flush();
        stateService.getAllKeys();
    }

    hook.register('sessionStart', ["$cookies", "teamsite", "brand", "coremetrics", "services", "$q", "sessionData", "brandConfig", "$interval", "session", "appConfig", "$state", "appId", "$timeout", function($cookies, teamsite, brand, coremetrics, services, $q, sessionData, brandConfig, $interval, session, appConfig, $state, appId, $timeout) {
        $interval.cancel(session.keepAlivePromise);

        if ($cookies.get("adminUser")) {
            return $q.all({
                  'entitlementService': services.cmcrst.entitlementService().getEntitlements()
              }).then(function (responses) {
                sessionData.entitlements = true;
            }, function (error){
                services.dps.logout().logout().then(
                    function (response) {
                        $timeout(function() {
                            sessionData.entitlements = false;
                            $cookies.remove("adminUser");
                            session.end();
                            $state.go('admin', {failedEntitlement: true});
                        }, 500);
                    },
                    function (error) {
                        console.log(error);
                    }
                );
                console.log(error);
            })
        } else {
            session.validateLogin = function(){
                return $q.when();
            };
        }

        return '';
    }]);

    hook.register('sessionEnd', ["$cookies", "teamsite", "brand", "coremetrics", "services", "$q", "sessionData", "brandConfig", "$interval", "session", "appConfig", "$state", "appId", "$timeout", function($cookies, teamsite, brand, coremetrics, services, $q, sessionData, brandConfig, $interval, session, appConfig, $state, appId, $timeout) {
        if ($cookies.get("adminUser")){
            $state.go('admin');
        }
        return '';
    }]);

}]);

app.config(['$locationProvider', '$urlRouterProvider', function($locationProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/home');
    $locationProvider.html5Mode(true);
}]);
