module.exports = function (app) {
    "use strict";

    app.controller('pages.admin.admin-support', ['$scope', 'stateService', '$state', 'services', function ($scope, stateService, $state, services){

        $scope.goToHome = function(){
            $state.go('admin');
        };

    }]);
};