module.exports = function (app) {
    "use strict";

    require('./admin-tools/admin-splash')(app);
    require('./admin-tools/search-filter')(app);
    require('./admin-tools/upload-members')(app);
    require('./admin-tools/email-confirmation')(app);
    require('./admin-tools/changes-confirmation')(app);
    require('./admin-tools/search-results')(app);
    require('./admin-tools/admin-support')(app);
    require('./admin-tools/admin-questionnaires-notes')(app);
    require('../../services/cmcrst/searchSurvey')(app);
    require('../../services/cmcrst/getSurveyDetails')(app);

    app.controller('pages.admin', ['$scope', 'stateService', '$state', '$rootScope', '$window', 'session', 'sessionData', '$cookies', 'services',
                        function ($scope, stateService, $state, $rootScope, $window, session, sessionData, $cookies, services) {

        $scope.headings = "QUESTIONNAIRE ADMINISTRATION";
        $scope.isBadRequestErr = false;
        $scope.isInternalServerErr = false;
        $scope.isOtherErr = false;
        $scope.failedEntitlement = $state.params.failedEntitlement;

        var createPayload = function (formData) {
            var regPayload = {
                payload: {
                    username: formData.username,
                    password: formData.userPassword
                }
            };
            return regPayload;
        };

        $rootScope.goBack = function(){
            $window.history.back();
        };

        $rootScope.goBackToDashboard = function(){
            $state.go("admin.admin-splash");
        };

        $rootScope.supportPage = function(){
            $state.go('admin.admin-support');
        };

        function clearError() {
            $scope.isBadRequestErr = false;
            $scope.isInternalServerErr = false;
            $scope.isOtherErr = false;
            $scope.failedEntitlement = false;
        }

        //ERROR
        function handleErrorResponse(error) {
            clearError();
            if (error && error.status) {
                if (error.status === 401 || error.status === 403) {
                    $scope.isBadRequestErr = true;
                } else if (error.status === 500) {
                    $scope.isInternalServerErr = true;
                } else {
                    $scope.isOtherErr = true;
                }
            }
        }


        $scope.formData = {
            'userName': '',
            'userPassword': ''
        };


        $scope.authenticateAdmin = function () {
            clearError();
            services.dps.loginDetails().login($scope.formData.userName, $scope.formData.userPassword).then(
                function (response) {
                    $state.go("admin.admin-splash");
                },
                function (error) {
                    console.log(error);
                    handleErrorResponse(error);
                }
            );
        };
    }]);

    return [
        {
            state: "admin",
            url: "/admin",
            postPassword: false,
            params: {
                failedEntitlement: false
            },
            views: {
                'header@': {
                    template: '<article data-svy-app-header></article>'
                },
                'body@': {
                    template: require('page-templates/content-template.html'),
                    controller: "pages.admin"
                },
                "content@admin": {
                    //http://localhost:4502/apps/ehs/svyweb/app/pages/admin/admin.html
                    templateUrl: "admin.svywebTemplate.html/pages/admin/admin"
                },
                'footer@': {
                    template: '<article data-svy-app-footer></article>'
                }
            }
        },
        {
            state: "admin.admin-splash",
            url: "/admin-splash",
            postPassword: true,
            views: {
                "content@admin": {
                    templateUrl: "admin/admin-splash.svywebTemplate.html/pages/admin/admin-tools/admin-splash",
                    controller: "pages.admin.admin-splash"
                }
            }
        },
        {
            state: "admin.search-filter",
            url: "/search-filter",
            postPassword: true,
            views: {
                "content@admin": {
                    templateUrl: "admin/search-filter.svywebTemplate.html/pages/admin/admin-tools/search-filter",
                    controller: "pages.admin.search-filter"
                }
            }
        },
        {
            state: "admin.upload-members",
            url: "/upload-members",
            postPassword: true,
            views: {
                "content@admin": {
                    templateUrl: "admin/upload-members.svywebTemplate.html/pages/admin/admin-tools/upload-members",
                    controller: "pages.admin.upload-members"
                }
            }
        },
        {
            state: "admin.email-confirmation",
            url: "/email-confirmation",
            postPassword: true,
            params: {fileUpload: null},
            views: {
                "content@admin": {
                    templateUrl: "admin/email-confirmation.svywebTemplate.html/pages/admin/admin-tools/email-confirmation",
                    controller: "pages.admin.email-confirmation"
                }
            }
        },
        {
            state: "admin.changes-confirmation",
            url: "/changes-confirmation",
            postPassword: true,
            views: {
                "content@admin": {
                    templateUrl: "admin/changes-confirmation.svywebTemplate.html/pages/admin/admin-tools/changes-confirmation",
                    controller: "pages.admin.changes-confirmation"
                }
            }
        },
        {
            state: "admin.admin-support",
            url: "/admin-support",
            postPassword: true,
            views: {
                "content@admin": {
                    templateUrl: "admin/admin-support.svywebTemplate.html/pages/admin/admin-tools/admin-support",
                    controller: "pages.admin.admin-support"
                }
            }
        },
        {
            state: "admin.admin-search-results",
            url: "/search-results",
            postPassword: true,
            params: {searchResults: null},
            views: {
                "content@admin": {
                    templateUrl: "admin/search-results.svywebTemplate.html/pages/admin/admin-tools/search-results",
                    controller: "pages.admin.search-results"
                }
            }
        },
        {
            state: "admin.questionnaires-notes",
            url: "/questionnaires-notes",
            postPassword: true,
            params: {reviewDetails: null},
            views: {
                "content@admin": {
                    templateUrl: "admin/questionnaires-notes.svywebTemplate.html/pages/admin/admin-tools/admin-questionnaires-notes",
                    controller: "pages.admin.admin-questionnaires-notes"
                }
            }
        }
    ];
};
