module.exports = function (app) {
    "use strict";
    app.controller('pages.health-survey.dependents-selection', ['$scope', 'stateService', '$state', 'services', 'surveyUtil', 'coremetrics', function ($scope, stateService, $state, services, surveyUtil, coremetrics){

        $scope.completeSurvey = angular.equals($scope.survey.formData, {}) ? stateService.getData('completeSurvey') : $scope.survey.formData;
        if(typeof $scope.completeSurvey === 'undefined' || $scope.completeSurvey === null){
            $state.go('health-survey');
        }

        $scope.dependentsSurveyStatus = [];

        $scope.dependents = surveyUtil.getSubscriberAndDependents($scope.completeSurvey);

        for(var dependent in $scope.dependents){
            var dependentId = $scope.dependents[dependent].dependentId ? $scope.dependents[dependent].dependentId : 0;
            if(surveyUtil.isApplicantSurveyComplete($scope.completeSurvey, dependentId)){
                $scope.dependents[dependent].complete = true;
            } else {
                $scope.dependents[dependent].complete = false;
            }
        }

        var dependentsOnly = surveyUtil.getDependentsOnly($scope.completeSurvey);

        $scope.goToSurveyOrSubmit = function(){
            //Submit the survey
            $scope.SubmitServiceError = false;
            if(surveyUtil.allSurveysCompleted($scope.completeSurvey)){
                services.cmcrst.submitUserSurveyDetails().putData({payload: $scope.completeSurvey}).then(
                    function(response){
                        if(dependentsOnly.length > 0){
                            coremetrics.finishConversion('Complete Survey', 'Dependent Complete Survey');
                        } else {
                            coremetrics.finishConversion('Complete Survey', 'Self Complete Survey');
                        }
                        $state.go('health-survey.submission-confirmation');
                    },
                    function(surveyError){
                        $scope.loadingService = false;
                        $scope.SubmitServiceError = true;
                        console.log(surveyError);
                        surveyUtil.ifNoAccess(surveyError);
                    }
                );
            } else {
                //Else get the next user
                $scope.survey.currentUser = surveyUtil.getNextUserKey($scope.completeSurvey);
                stateService.storeData('currentUser', $scope.survey.currentUser);
                //Go to phone-number designation page if currentUser key is "0"
                if($scope.survey.currentUser){
                    if($scope.survey.currentUser === "0"){
                        $state.go("health-survey.phone-number-designation");
                    } else {
                        $state.go("health-survey.survey-step1");
                    }
                }
            }
        };

        $scope.goToReview = function(){
           $scope.survey.currentUser = '0';
           stateService.storeData('currentUser','0');
           stateService.storeData('reviewAndSubmit',true);
           coremetrics.elementTag('Review and Submit', 'HEALTH SURVEY');
           $state.go("health-survey.survey-review",{reviewAndSubmit: true});
        };
        
        $scope.ifAllSurveysComplete = false;
        $scope.ifAllSurveysIncomplete = false;
        $scope.ifSurveyInProgress = false;

        if(surveyUtil.allSurveysCompleted($scope.completeSurvey)){
            $scope.ifAllSurveysComplete = true;
        } else {
            if(surveyUtil.allSurveysIncomplete($scope.completeSurvey)){
                $scope.ifAllSurveysIncomplete = true;
            } else {
                $scope.ifSurveyInProgress = true;
            }
        }



    }]);
};




