module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'authenticateUser',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/user/public/authenticate',
            cache: false,
            postData: function (payload) {
                return this.post(payload);
            }
        });
    }]);
};
