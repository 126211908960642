module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'submitSurveyDetails',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/admin/secure/survey-submit',
            putData: function (payload) {
                return this.put(payload);
            }
        });
    }]);
    app.run(["Service", function (Service) {
        new Service({
            name: 'submitUserSurveyDetails',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/user/secure/survey-submit',
            putData: function (payload) {
                return this.put(payload);
            }
        });
    }]);
};


