/**
 * Created by lidc8nw on 10/30/2018.
 */

module.exports = function (app) {
    "use strict";
    app.controller('pages.admin.search-filter', ['$scope', 'stateService', '$state',  'services', function ($scope, stateService, $state, services) {
        $scope.formData = {};
        $scope.formData.id="1";
        $scope.isDisable = true;
        $scope.isError = false;
        $scope.isServiceFailed = false;


        $scope.checkform = function() {
            if( !!$scope.formData.employer &&  $scope.formData.employer.length > 0 ||
                (!!$scope.formData.firstName && $scope.formData.firstName.length > 0 &&
                !!$scope.formData.lastName && $scope.formData.lastName.length > 0 )||
                (!!$scope.formData.endDate && $scope.formData.endDate.length > 0 &&
                !!$scope.formData.startDate && $scope.formData.startDate.length > 0 )||
                !!$scope.formData.status && $scope.formData.status.length > 0
            ){
                $scope.isDisable = false;
            }
            else {
                $scope.isDisable = true;
            }
        };

        var clean = function(obj) {
            for (var propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined ||obj[propName].length === 0) {
                    delete obj[propName];
                }
            }
            return obj;
        };

        $scope.searchFilter = function () {
           var cleanData = clean($scope.formData);
            $scope.isDisable = true;
            if(!cleanData.status){
                delete cleanData.status;
            }
            var payload = {
                payload: cleanData
            };

            $scope.isError = false;
            $scope.isServiceFailed = false;
            services.cmcrst.searchSurvey().postData(payload).then(

                function (response) {
                    if(!!response && response.data.payload.length > 0) {
                        stateService.storeData('searchCriteria', JSON.stringify(cleanData));
                        stateService.storeData('searchResults',response.data.payload);
                        $state.go("admin.admin-search-results",{searchResults: response.data.payload});
                    }else {
                        $scope.isError = true;
                        $scope.isDisable = false;
                    }
                },
                function(error){
                    $scope.isServiceFailed = true;
                    $scope.isDisable = false;
                }

            );
        };
    }]);
};
