module.exports = function (app) {
    app.directive('validatorName', function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.pattern = function (value) {
                    value = value || '';

                    return value.search(/^$|^(?=.*[a-zA-Z].\-*)([a-zA-Z0-9 '\-]+)$/) > -1;
                };
            }
        };
    });
}