module.exports = function (app) {
    var sjcl = require('utilities/sjcl')();
    app.directive('storageKey', ['stateService', function (stateService) {
        return {
            require: 'ngModel',
            scope: {
                local: '=ngModel'
            },
            link: function (scope, element, attribute, ngModel) {
                if (element[0].type === 'text') {
                    ngModel.$options = {
                        updateOn: "default click blur",
                        debounce: {"default": 500, "blur": 0}
                    };
                }
                // Set Value of the field from existing data.
                var valueFromStorage = stateService.getData(attribute.storageKey);
                if (valueFromStorage && valueFromStorage.match &&
                    valueFromStorage.match(/\d{4}-\d{2}-\d{2}T.*Z/)) {
                    valueFromStorage = new Date(valueFromStorage);
                }
                scope.local = valueFromStorage;
                // Set change listener to store data on update, should only happen after successful validations, so we're not storing invalid data.
                scope.$watch('local', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        stateService.storeData(attribute.storageKey, scope.local);
                    }
                }, true);
            }
        };
    }]);
    app.factory('stateService', ['$cookies', '$window', 'cryptoUi',
        function ($cookies, $window, cryptoUi) {
            var appStorage = {};

            function Tupperware(storageName) {

                var here = this;

                var appStorageKey = storageName;
                this.storeData = function (key, value) {
                    appStorage[key] = value;
                };
                this.getData = function (key) {
                    //return appStorage[key] || null;
                    return typeof appStorage[key] != "undefined" ?  appStorage[key] :  null;
                };
                this.deleteKey = function (key) {
                    var success = delete appStorage[key];
                    return success;
                };

                this.save = function () {
                    sessionStorage.setItem(appStorageKey, cryptoUi.encrypt(angular.toJson(appStorage)));
                };
                this.load = function (flush) {
                    var appData = sessionStorage.getItem('app');
                    var decryptedAppData = cryptoUi.decrypt(appData);
                    var parsedAppData = angular.fromJson(decryptedAppData);
                    flush && this.flush();
                    appStorage = parsedAppData || {};
                };
                this.flush = function (keepAlive) {

                    var flushEscape = {};
                    angular.forEach(keepAlive, function (key) {
                        flushEscape[key] = here.getData(key);
                    });

                    appStorage = {};
                    sessionStorage.removeItem(appStorageKey);

                    angular.forEach(flushEscape, function (val, key) {
                        if (val !== null) {
                            here.storeData(key, val);
                        }
                    });

                    return true;
                };
                this.getAllData = function () {
                    return appStorage;
                };
                this.setAllData = function (data) {
                    appStorage = data;
                    return appStorage;
                };
                this.deleteAllData = function () {
                    appStorage = {};
                    return true;
                };
                this.getAllKeys = function () {
                    return Object.keys(appStorage);
                };
                this.checkKey = function (key) {
                    return appStorage.hasOwnProperty(key);
                };
            }

            var rubberMaid = new Tupperware('app');
            rubberMaid.load(true);
            $window.onunload = function () {
                rubberMaid.save();
            };
            return rubberMaid;
        }]);
    app.factory('cryptoUi', ['$cookies', function ($cookies) {
        var passkey = $cookies.get('medicareToken1');
        var iv = $cookies.get('medicareToken2');
        var salt = $cookies.get('medicareToken3');

        function generateKey(length) {
            return Math.random().toString(36).substr(2, length);
        }

        function CryptoUiService() {
            var manageTokens = function () {
                if (!passkey || !iv || !salt) {
                    passkey = generateKey(12); //angular.fromJson(sjcl.encrypt('temp', 'temp', {ks: 256})).iv;
                    iv = angular.fromJson(sjcl.encrypt(passkey, 'temp', {ks: 256})).iv;
                    salt = angular.fromJson(sjcl.encrypt(passkey, 'temp', {ks: 256})).salt;
                    $cookies.put('medicareToken1', passkey, {'secure': true});
                    $cookies.put('medicareToken2', iv, {'secure': true});
                    $cookies.put('medicareToken3', salt, {'secure': true});
                }
            };
            manageTokens();
            this.encrypt = function (value) {
                if (value) {
                    value = angular.toJson({value: value});
                    var encryptedData = sjcl.encrypt(passkey, value, {
                        ks: 256,
                        iv: iv,
                        salt: salt
                    });
                    return angular.fromJson(encryptedData).ct;
                }
            };
            this.decrypt = function (value) {
                var decryptedData;
                try {
                    decryptedData = sjcl.decrypt(passkey, angular.toJson({
                        ks: 256,
                        iv: iv,
                        salt: salt,
                        ct: value
                    }));
                    return angular.fromJson(decryptedData).value;
                } catch (e) {
                    console.error(e);
                }
            };
        }

        return new CryptoUiService();
    }]);
};