module.exports = function (app) {
    app.directive('dateMin', function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.min = function (value) {
                    if (value) {
                        return (new Date(attributes.dateMin)).getTime() <= (new Date(value)).getTime();
                    }

                    return true;
                };
            }
        };
    });
    app.directive('dateMax', function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.max = function (value) {
                    if (value) {
                        return (new Date(attributes.dateMax)).getTime() >= (new Date(value)).getTime();
                    }

                    return true;
                };
            }
        };
    });
}