module.exports = function (app) {
    app.run(["Service", function (Service) {
        new Service({
            name: 'getEmailTemplate',
            group: 'cmcrst',
            url: '/dpsint/cmcrst/x-services/healthsurvey/user/public/email-template',
            cache: false,
            getData: function () {
                return this.get();
            }
        });
    }]);
};